import { createSlice } from "@reduxjs/toolkit";

export const itinerarySlice = createSlice({
    name: "itinerary",
    initialState: {
        itineraryData: [],
        loader: {
            type: "",
            loading: false,
        },
        projectData: {},
        grandTotal: 0,
        citiesByDay: [],
        projectTerms: "",
    },
    reducers: {
        setItineraryData: (state, action) => {
            state.itineraryData = action.payload.itineraryData;
            state.projectData = action.payload.projectData;
            state.grandTotal = action.payload.grandTotal;
            state.projectTerms = action.payload.projectTerms;
            state.citiesByDay = action.payload.citiesByDay;
        },

        setLoader: (state, action) => {
            state.loader = action.payload;
        },

        addItineraryProduct: (state, action) => {
            const { product, itineraryId } = action.payload;
            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    itinerary.products.push(product);
                }
                return itinerary;
            });
        },

        removeItineraryProduct: (state, action) => {
            const { product, itineraryId } = action.payload;
            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    itinerary.products = itinerary.products.filter(
                        (p) => p.productCode !== product.productCode,
                    );
                }
                return itinerary;
            });
        },

        deleteItineraryFlight: (state, action) => {
            const { flightData, isFlightsPackage } = action.payload;
            state.itineraryData[0] = {
                ...state.itineraryData[0],
                separatedFlights: !isFlightsPackage
                    ? state.itineraryData[0].separatedFlights.filter(
                          (flight) =>
                              !flight.slices.some(
                                  (slice) =>
                                      slice?.duration ===
                                          flightData?.duration &&
                                      slice?.origin.iata_code ===
                                          flightData?.origin?.iata_code &&
                                      slice?.destination.iata_code ===
                                          flightData?.destination?.iata_code,
                              ),
                      )
                    : [],
                flights: isFlightsPackage
                    ? null
                    : state.itineraryData[0].flights,
            };
        },

        deleteItineraryTransfer: (state, action) => {
            const { transferRateKey, itineraryId } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    itinerary.transports = itinerary.transports.filter(
                        (transfer) =>
                            transfer.transferRateKey !== transferRateKey,
                    );
                }
                return itinerary;
            });
        },

        updateItinerariesTransfers: (state, action) => {
            const { itineraries } = action.payload;
            state.itineraryData = state.itineraryData.map((itinerary) => {
                const updatedItinerary = itineraries.find(
                    (it) => it._id === itinerary._id,
                );
                if (updatedItinerary) {
                    itinerary.transports = updatedItinerary.transports;
                }
                return itinerary;
            });
        },

        updateNewSelectedFlight: (state, action) => {
            const { isFlightsPackage, flightData, newFlight } = action.payload;

            if (isFlightsPackage) {
                state.itineraryData[0].flights = newFlight;
            } else {
                state.itineraryData[0].separatedFlights =
                    state.itineraryData[0].separatedFlights.map((flight) => {
                        if (
                            flight.slices.some(
                                (slice) => slice?.id === flightData?.id,
                            )
                        ) {
                            return newFlight;
                        }

                        return flight;
                    });
            }
        },

        addNewHotel: (state, action) => {
            const { itineraryId, newHotel } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    itinerary.hotels.push(newHotel);
                }

                return itinerary;
            });
        },

        addManualFlightToStore: (state, action) => {
            const { newFlight } = action.payload;

            const itineraryFlights = state.itineraryData[0];

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary?._id !== itineraryFlights?._id) return itinerary;

                const newSlices = { slices: [newFlight] };

                if (itineraryFlights?.flights) {
                    return {
                        ...itinerary,
                        flights: {
                            ...itinerary.flights,
                            slices: [...itinerary.flights.slices, newFlight],
                        },
                    };
                }

                return {
                    ...itinerary,
                    separatedFlights: [
                        ...(itinerary.separatedFlights || []),
                        newSlices,
                    ],
                };
            });
        },

        updateItineraryProducts: (state, action) => {
            const { itineraryId, products } = action.payload;
            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    itinerary.products = products;
                }
                return itinerary;
            });
        },

        setSelectedHotel: (state, action) => {
            const { itineraryId, hotelToAdd, add } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    if (add) {
                        itinerary.hotels.push(hotelToAdd);
                    }
                    itinerary.hotelSelected = hotelToAdd;
                }
                return itinerary;
            });
        },

        removeHotel: (state, action) => {
            const { itineraryId, hotelIndex } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    const hotelDeleted = itinerary.hotels.splice(hotelIndex, 1);
                    if (
                        String(hotelDeleted[0].code) ===
                        String(itinerary.hotelSelected.code)
                    ) {
                        itinerary.hotelSelected = {};
                    }
                }

                return itinerary;
            });
        },

        updateOptionalHotels: (state, action) => {
            const { itineraryId, newHotel, hotel } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    if (newHotel) {
                        return {
                            ...itinerary,
                            optionalHotels: [
                                ...itinerary.optionalHotels,
                                newHotel,
                            ],
                        };
                    } else {
                        return {
                            ...itinerary,
                            optionalHotels: itinerary?.optionalHotels?.filter(
                                (optionalHotel) =>
                                    String(optionalHotel.code) !==
                                    String(hotel.code),
                            ),
                        };
                    }
                }

                return itinerary;
            });
        },

        removeOptionalHotel: (state, action) => {
            const { itineraryId, hotel } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    return {
                        ...itinerary,
                        optionalHotels: itinerary?.optionalHotels?.filter(
                            (optionalHotel) =>
                                String(optionalHotel.code) !==
                                String(hotel.code),
                        ),
                    };
                }
                return itinerary;
            });
        },

        updateHotelRate: (state, action) => {
            const { itineraryId, hotel } = action.payload;

            state.itineraryData = state.itineraryData.map((itinerary) => {
                if (itinerary._id === itineraryId) {
                    return {
                        ...itinerary,
                        hotels: itinerary.hotels.map((hotelItem) => {
                            if (String(hotelItem.code) === String(hotel.code)) {
                                return hotel;
                            }
                            return hotelItem;
                        }),
                        hotelSelected:
                            String(itinerary?.hotelSelected?.code) ===
                            String(hotel.code)
                                ? hotel
                                : itinerary.hotelSelected,
                    };
                }
                return itinerary;
            });
        },

        updateSelectedActivityTicket:(state,action)=>{
            const { itineraryId , productCode , selectedTicket } = action.payload;

            state.itineraryData=state.itineraryData.map(itinerary=>{
                if(itinerary._id===itineraryId){
                    itinerary.products=itinerary.products.map(product=>{
                        if(product.productCode===productCode){
                            return {
                                ...product,
                                selectedTicket
                            }
                        }
                        return product;
                    })
                }
                return itinerary;
            })
        }
    },
});

export const {
    setItineraryData,
    setLoader,
    addItineraryProduct,
    removeItineraryProduct,
    deleteItineraryFlight,
    deleteItineraryTransfer,
    updateItinerariesTransfers,
    updateNewSelectedFlight,
    addNewHotel,
    addManualFlightToStore,
    updateItineraryProducts,
    setSelectedHotel,
    removeHotel,
    updateOptionalHotels,
    removeOptionalHotel,
    updateHotelRate,
    updateSelectedActivityTicket,
} = itinerarySlice.actions;

export default itinerarySlice.reducer;
