import { getAirpotsList } from "./getAirportList";

export const parseFlightData = async (tripData) => {
    const { departureCity, cabinClass, destinations } = tripData;
    if (!Array.isArray(destinations)) {
        return [];
    }
    const cities = await Promise.all(
        [departureCity, ...destinations].map(async (destination) => {
            const response = await getAirpotsList(
                destination?.city?.name || destination?.name,
            );
            return {
                name: destination?.city?.name || destination?.name,
                code: response?.[0]?.code,
            };
        }),
    );
    const slices = [];
    slices.push({
        origin: cities[0].code,
        destination: cities[1].code,
        departure_date: new Date(destinations[0].endDate)
            .toISOString()
            .split("T")[0],
    });
    for (let i = 1; i < cities.length - 1; i++) {
        slices.push({
            origin: cities[i].code,
            destination: cities[i + 1].code,
            departure_date: new Date(destinations[i].endDate)
                .toISOString()
                .split("T")[0],
        });
    }

    slices.push({
        origin: cities[cities.length - 1].code,
        destination: cities[0].code,
        departure_date: new Date(destinations[destinations.length - 1].endDate)
            .toISOString()
            .split("T")[0],
    });

    const passengers = [];
    passengers.push({
        type: "adult",
    });

    return {
        slices,
        passengers,
        cabin_class: cabinClass,
        currency: "EUR",
    };
};
