// assets
//
import { _mock } from "./_mock";
import { t } from "i18next";
// ----------------------------------------------------------------------

export const PROJECT_STATUS_OPTIONS = [
    { value: "needs", label: "Needs" },
    { value: "proposal", label: "Proposal" },
    { value: "booking", label: "Booking" },
    { value: "pre-travel", label: "Pre-Travel" },
    { value: "in-travel", label: "In-Travel" },
    { value: "post-travel", label: "Post-Travel" },
];

export const PROJECT_CURRENCIES = [
    { value: "EUR", label: t("Euro") },
    { value: "USD", label: t("American Dollar") },
    { value: "CHF", label: t("Swiss Franc") },
];

export const PROJECT_CONTINENTS = [
    { value: "Europe", label: "Europe" },
    { value: "Asia", label: "Asia" },
    { value: "Africa", label: "Africa" },
    { value: "America", label: "America" },
    { value: "Oceania", label: "Oceania" },
    { value: "Antartica", label: "Antartica" },
];

export const PROJECT_TRAVEL_TYPE = [
    { value: "Cultural", label: "Cultural" },
    { value: "Adventure", label: "Adventure" },
    { value: "Relax", label: "Relax" },
    { value: "Business", label: "Business" },
    { value: "Family", label: "Family" },
    { value: "Romantic", label: "Romantic" },
    { value: "Individual", label: "Individual" },
    { value: "Group", label: "Group" },
    { value: "Other", label: "Other" },
];

export const EXPENSES_STATUS_OPTIONS = [
    { value: "all", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "rejected", label: "Rejected" },
];
