import { Suspense, lazy } from "react";

import { AuthGuard } from "src/auth/guard";
import DashboardLayout from "src/layouts/dashboard";
import { LoadingScreen } from "src/components/loading-screen";
import { Outlet } from "react-router-dom";

const AiAcquisitionPage = lazy(
    () => import("src/pages/dashboard/aipilot/acquisition"),
);

const AiConversionPage = lazy(
    () => import("src/pages/dashboard/aipilot/conversion"),
);
const AiExperiencePage = lazy(
    () => import("src/pages/dashboard/aipilot/experience"),
);
const AiEngagementPage = lazy(
    () => import("src/pages/dashboard/aipilot/engagement"),
);

// ADMIN
const AdminBookingsPage = lazy(
    () => import("src/pages/dashboard/admin/admin-bookings"),
);
const AdminBookingsDetailsPage = lazy(
    () => import("src/pages/dashboard/admin/admin-bookings-details"),
);

const AdminBookingsVoucherPage = lazy(
    () => import("src/pages/dashboard/admin/admin-booking-voucher-view"),
);

const AdminSalesReportPage = lazy(
    () => import("src/pages/dashboard/admin/admin-sales-report"),
);
const AdminTzClientsPage = lazy(
    () => import("src/pages/dashboard/admin/admin-tzclients"),
);
const AdminTzDepartmentsPage = lazy(
    () => import("src/pages/dashboard/admin/admin-tzdepartments"),
);
const AdminTzTravelPoliciesPage = lazy(
    () => import("src/pages/dashboard/admin/admin-tztravelPolicies"),
);
const AdminTzClientDetailsPage = lazy(
    () => import("src/pages/dashboard/admin/admin-tzclients-details"),
);
const AdminTzClientsReportPage = lazy(
    () => import("src/pages/dashboard/admin/admin-tzclients-report"),
);

const AdminAgencyNewPage = lazy(
    () => import("src/pages/dashboard/admin/admin-agency-new"),
);

// OVERVIEW
const IndexPage = lazy(() => import("src/pages/dashboard/app"));
const HomePage = lazy(() => import("src/pages/dashboard/home"));
const SalesOverviewPage = lazy(
    () => import("src/pages/dashboard/sales-overview"),
);
const FinancialsPage = lazy(() => import("src/pages/dashboard/financials"));
const PerformanceMetricsPage = lazy(
    () => import("src/pages/dashboard/performance-metrics"),
);
const OverviewEcommercePage = lazy(
    () => import("src/pages/dashboard/ecommerce"),
);
const OverviewAnalyticsPage = lazy(
    () => import("src/pages/dashboard/analytics"),
);
const OverviewBankingPage = lazy(() => import("src/pages/dashboard/banking"));
const OverviewBookingPage = lazy(() => import("src/pages/dashboard/booking"));
const OverviewFilePage = lazy(() => import("src/pages/dashboard/file"));
// PRODUCT
const ProductDetailsPage = lazy(
    () => import("src/pages/dashboard/product/details"),
);
const ProductListPage = lazy(() => import("src/pages/dashboard/product/list"));
const ProductCreatePage = lazy(() => import("src/pages/dashboard/product/new"));
const ProductEditPage = lazy(() => import("src/pages/dashboard/product/edit"));
// ORDER
const OrderListPage = lazy(() => import("src/pages/dashboard/order/list"));
const OrderDetailsPage = lazy(
    () => import("src/pages/dashboard/order/details"),
);
// BOOKING
const BookingListPage = lazy(() => import("src/pages/dashboard/booking/list"));
const BookingDetailsPage = lazy(
    () => import("src/pages/dashboard/booking/details"),
);
// INVOICE
const InvoiceListPage = lazy(() => import("src/pages/dashboard/invoice/list"));
const InvoiceDetailsPage = lazy(
    () => import("src/pages/dashboard/invoice/details"),
);
const InvoiceCreatePage = lazy(() => import("src/pages/dashboard/invoice/new"));
const InvoiceEditPage = lazy(() => import("src/pages/dashboard/invoice/edit"));
// QUOTATION
const QuotationListPage = lazy(
    () => import("src/pages/dashboard/quotation/list"),
);
const QuotationDetailsPage = lazy(
    () => import("src/pages/dashboard/quotation/details"),
);
const QuotationCreatePage = lazy(
    () => import("src/pages/dashboard/quotation/new"),
);
const QuotationEditPage = lazy(
    () => import("src/pages/dashboard/quotation/edit"),
);
// TASK
const TaskListPage = lazy(() => import("src/pages/dashboard/task/list"));
const TaskCreatePage = lazy(() => import("src/pages/dashboard/task/new"));

// PROJECT
const ProjectListPage = lazy(() => import("src/pages/dashboard/project/list"));
const ProjectProfilePage = lazy(
    () => import("src/pages/dashboard/project/profile"),
);
const ProjectCreatePage = lazy(() => import("src/pages/dashboard/project/new"));

// USER
const UserProfilePage = lazy(() => import("src/pages/dashboard/user/profile"));
const UserCardsPage = lazy(() => import("src/pages/dashboard/user/cards"));
const UserListPage = lazy(() => import("src/pages/dashboard/user/list"));
const UserAccountPage = lazy(() => import("src/pages/dashboard/user/account"));
const UserCreatePage = lazy(() => import("src/pages/dashboard/user/new"));
const UserEditPage = lazy(() => import("src/pages/dashboard/user/edit"));

// DEPARTMENT

const DepartmentListPage = lazy(
    () => import("src/pages/dashboard/department/list"),
);
const DepartmentCreatePage = lazy(
    () => import("src/pages/dashboard/department/new"),
);
const DepartmentEditPage = lazy(
    () => import("src/pages/dashboard/department/edit"),
);

// DEPARTMENT

const TravelPolicyListPage = lazy(
    () => import("src/pages/dashboard/travelPolicy/list"),
);
const TravelPolicyCreatePage = lazy(
    () => import("src/pages/dashboard/travelPolicy/new"),
);
const TravelPolicyEditPage = lazy(
    () => import("src/pages/dashboard/travelPolicy/edit"),
);

//APPROVALS

const ApprovalListPage = lazy(() => import("src/pages/dashboard/approvals"));

//REPORTS

const ReportsPage = lazy(()=>import("src/pages/dashboard/Reports"))

// LEAD
const LeadProfilePage = lazy(() => import("src/pages/dashboard/lead/profile"));
const LeadCardsPage = lazy(() => import("src/pages/dashboard/lead/cards"));
const LeadListPage = lazy(() => import("src/pages/dashboard/lead/list"));
const LeadAccountPage = lazy(() => import("src/pages/dashboard/lead/account"));
const LeadCreatePage = lazy(() => import("src/pages/dashboard/lead/new"));
const LeadEditPage = lazy(() => import("src/pages/dashboard/lead/edit"));
/* const LeadCampaignPage = lazy(() =>
  import("src/pages/dashboard/lead/campaign")
); */

// GROUPLEAD
const GroupLeadProfilePage = lazy(
    () => import("src/pages/dashboard/grouplead/profile"),
);
const GroupLeadCardsPage = lazy(
    () => import("src/pages/dashboard/grouplead/cards"),
);
const GroupLeadListPage = lazy(
    () => import("src/pages/dashboard/grouplead/list"),
);
const GroupLeadAccountPage = lazy(
    () => import("src/pages/dashboard/grouplead/account"),
);
const GroupLeadCreatePage = lazy(
    () => import("src/pages/dashboard/grouplead/new"),
);
const GroupLeadEditPage = lazy(
    () => import("src/pages/dashboard/grouplead/edit"),
);

// CAMPAIGN
const CampaignProfilePage = lazy(
    () => import("src/pages/dashboard/campaigns/profile"),
);
const CampaignCardsPage = lazy(
    () => import("src/pages/dashboard/campaigns/cards"),
);
const CampaignListPage = lazy(
    () => import("src/pages/dashboard/campaigns/list"),
);
const CampaignCreatePage = lazy(
    () => import("src/pages/dashboard/campaigns/new"),
);
const CampaignCreateContactPage = lazy(
    () => import("src/pages/dashboard/campaigns/contactnew"),
);
const CampaignEditPage = lazy(
    () => import("src/pages/dashboard/campaigns/edit"),
);
const ContactFormEditPage = lazy(
    () => import("src/pages/dashboard/campaigns/contact-form-edit"),
);
const CampaignContactPage = lazy(
    () => import("src/pages/dashboard/campaigns/contact"),
);

// Customer
const CustomerProfilePage = lazy(
    () => import("src/pages/dashboard/customer/profile"),
);
const CustomerCardsPage = lazy(
    () => import("src/pages/dashboard/customer/cards"),
);
const IndividualsListPage = lazy(
    () => import("src/pages/dashboard/customer/individualList"),
);
const BusinessListPage = lazy(
    () => import("src/pages/dashboard/customer/businessList"),
);
const LeadsListPage = lazy(
    () => import("src/pages/dashboard/customer/leadList"),
);

const CustomerAccountPage = lazy(
    () => import("src/pages/dashboard/customer/account"),
);
const CustomerCreatePage = lazy(
    () => import("src/pages/dashboard/customer/new"),
);
const CustomerEditPage = lazy(
    () => import("src/pages/dashboard/customer/edit"),
);
// SALES PIPELINE
const SalesPipelineView = lazy(
    () => import("src/pages/dashboard/sales-pipeline-page"),
);
// SALES PIPELINE
const LeadsConvertingView = lazy(
    () => import("src/pages/dashboard/leads-converting"),
);
// Providers
const ProviderProfilePage = lazy(
    () => import("src/pages/dashboard/providers/profile"),
);
const ProviderListPage = lazy(
    () => import("src/pages/dashboard/providers/list"),
);
const ProviderCreatePage = lazy(
    () => import("src/pages/dashboard/providers/new"),
);
const ProviderEditPage = lazy(
    () => import("src/pages/dashboard/providers/edit"),
);

// Expenses
const ExpenseProfilePage = lazy(
    () => import("src/pages/dashboard/expense/details"),
);
const ExpenseListPage = lazy(() => import("src/pages/dashboard/expense/list"));
const ExpenseCreatePage = lazy(() => import("src/pages/dashboard/expense/new"));
const ExpenseEditPage = lazy(() => import("src/pages/dashboard/expense/edit"));
const ExpenseReportPage = lazy(
    () => import("src/pages/dashboard/expense/report"),
);

// Income
const IncomeProfilePage = lazy(
    () => import("src/pages/dashboard/income/details"),
);
const IncomeListPage = lazy(() => import("src/pages/dashboard/income/list"));
const IncomeCreatePage = lazy(() => import("src/pages/dashboard/income/new"));
const IncomeEditPage = lazy(() => import("src/pages/dashboard/income/edit"));

// DailyLedger
const LedgerProfilePage = lazy(
    () => import("src/pages/dashboard/dailyLedger/details"),
);
const LedgerListPage = lazy(
    () => import("src/pages/dashboard/dailyLedger/list"),
);
const LedgerCreatePage = lazy(
    () => import("src/pages/dashboard/dailyLedger/new"),
);
const LedgerEditPage = lazy(
    () => import("src/pages/dashboard/dailyLedger/edit"),
);

// Chart of Accounts
/* const AccountProfilePage = lazy(() =>
  import("src/pages/dashboard/chartOfAccounts/details")
); */
const AccountListPage = lazy(
    () => import("src/pages/dashboard/chartOfAccounts/list"),
);
const AccountCreatePage = lazy(
    () => import("src/pages/dashboard/chartOfAccounts/new"),
);
const AccountEditPage = lazy(
    () => import("src/pages/dashboard/chartOfAccounts/edit"),
);

// BLOG
const BlogPostsPage = lazy(() => import("src/pages/dashboard/post/list"));
const BlogPostPage = lazy(() => import("src/pages/dashboard/post/details"));
const BlogNewPostPage = lazy(() => import("src/pages/dashboard/post/new"));
const BlogEditPostPage = lazy(() => import("src/pages/dashboard/post/edit"));
// JOB
const JobDetailsPage = lazy(() => import("src/pages/dashboard/job/details"));
const JobListPage = lazy(() => import("src/pages/dashboard/job/list"));
const JobCreatePage = lazy(() => import("src/pages/dashboard/job/new"));
const JobEditPage = lazy(() => import("src/pages/dashboard/job/edit"));
// OURPRODUCTS
const OurproductsDetailsPage = lazy(
    () => import("src/pages/dashboard/ourproducts/details"),
);
const OurproductsListPage = lazy(
    () => import("src/pages/dashboard/ourproducts/list"),
);
const OurproductsCreatePage = lazy(
    () => import("src/pages/dashboard/ourproducts/new"),
);
const OurproductsEditPage = lazy(
    () => import("src/pages/dashboard/ourproducts/edit"),
);
// TOUR
const TourDetailsPage = lazy(() => import("src/pages/dashboard/tour/details"));
const TourListPage = lazy(() => import("src/pages/dashboard/tour/list"));
const TourCreatePage = lazy(() => import("src/pages/dashboard/tour/new"));
const TourEditPage = lazy(() => import("src/pages/dashboard/tour/edit"));
// PROMOTION
const PromotionDetailsPage = lazy(
    () => import("src/pages/dashboard/promotion/details"),
);
const PromotionListPage = lazy(
    () => import("src/pages/dashboard/promotion/list"),
);
const PromotionCreatePage = lazy(
    () => import("src/pages/dashboard/promotion/new"),
);
const PromotionEditPage = lazy(
    () => import("src/pages/dashboard/promotion/edit"),
);
// ACTIVITY
const ActivityListPage = lazy(
    () => import("src/pages/dashboard/activity/list"),
);
const ActivityDetailsPage = lazy(
    () => import("src/pages/dashboard/activity/details"),
);
const ActivityCheckoutPage = lazy(
    () => import("src/pages/dashboard/activity/checkout"),
);
// HOTEL VIEW
const HotelListPage = lazy(() => import("src/pages/dashboard/hotel/list"));
const HotelEditPage = lazy(() => import("src/pages/dashboard/hotel/edit"));
const HotelDetailsPage = lazy(
    () => import("src/pages/dashboard/hotel/details"),
);
const HotelCheckoutPage = lazy(
    () => import("src/pages/dashboard/hotel/checkout"),
);

// FLIGHTS
const FlightHomePage = lazy(() => import("src/pages/dashboard/flight"));
const FlightCheckoutPage = lazy(
    () => import("src/pages/dashboard/flight/checkout"),
);

// TRASNFERS
const TransferHomePage = lazy(() => import("src/pages/dashboard/transfer"));
const TransferCheckoutPage = lazy(
    () => import("src/pages/dashboard/transfer/checkout"),
);

// CAR RENTAL
const CarRentalHomePage = lazy(() => import("src/pages/dashboard/carRental"));
const CarRentalCheckoutPage = lazy(
    () => import("src/pages/dashboard/carRental/checkout"),
);

// CLIENT VIEW
const ClientViewPage = lazy(() => import("src/pages/dashboard/client-view"));
const ClientBookViewPage = lazy(
    () => import("src/pages/dashboard/client-book-view"),
);
// PROVIDER VIEW
const ProviderViewPage = lazy(
    () => import("src/pages/dashboard/provider-view"),
);

// FILE MANAGER
const FileManagerPage = lazy(() => import("src/pages/dashboard/file-manager"));
// APP
const ChatPage = lazy(() => import("src/pages/dashboard/chat"));
const MailPage = lazy(() => import("src/pages/dashboard/mail"));
const CalendarPage = lazy(() => import("src/pages/dashboard/calendar"));
const KanbanPage = lazy(() => import("src/pages/dashboard/kanban"));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(
    () => import("src/pages/dashboard/permission"),
);
// BLANK PAGE
const BlankPage = lazy(() => import("src/pages/dashboard/blank"));

//CRUISE
const CruiseListPage = lazy(() => import("src/pages/dashboard/cruise/list"));
const CruiseDetailsPage = lazy(
    () => import("src/pages/dashboard/cruise/details"),
);
// ----------------------------------------------------------------------

export const dashboardRoutes = [
    {
        path: "dashboard",
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <IndexPage />, index: true },
            { path: "ecommerce", element: <OverviewEcommercePage /> },
            { path: "analytics", element: <OverviewAnalyticsPage /> },
            { path: "banking", element: <OverviewBankingPage /> },
            { path: "bookingDashboard", element: <OverviewBookingPage /> },
            { path: "home", element: <HomePage /> },
            { path: "salesOverview", element: <SalesOverviewPage /> },
            { path: "financials", element: <FinancialsPage /> },
            { path: "performanceMetrics", element: <PerformanceMetricsPage /> },
            { path: "file", element: <OverviewFilePage /> },
            {
                path: "admin",
                children: [
                    { path: "bookings", element: <AdminBookingsPage /> },
                    { path: ":id", element: <AdminBookingsDetailsPage /> },
                    {
                        path: "tzclient/:id",
                        element: <AdminTzClientDetailsPage />,
                    },
                    { path: "salesreport", element: <AdminSalesReportPage /> },
                    { path: "agencies", element: <AdminTzClientsPage /> },
                    {
                        path: "departments",
                        element: <AdminTzDepartmentsPage />,
                    },
                    {
                        path: "travelPolicies",
                        element: <AdminTzTravelPoliciesPage />,
                    },
                    {
                        path: "clientsreport",
                        element: <AdminTzClientsReportPage />,
                    },
                    {
                        path: "bookingVoucher/:id",
                        element: <AdminBookingsVoucherPage />,
                    },
                    { path: "new-agency", element: <AdminAgencyNewPage /> },
                ],
            },
            {
                path: "task",
                children: [
                    { element: <UserProfilePage />, index: true },
                    { path: "list", element: <TaskListPage /> },
                    { path: "new", element: <TaskCreatePage /> },
                ],
            },
            {
                path: "project", // Add a forward slash before the path
                children: [
                    { element: <UserProfilePage />, index: true },
                    { path: "list", element: <ProjectListPage /> },
                    {
                        path: "profile/:projectId",
                        element: <ProjectProfilePage />,
                    },
                    { path: "new", element: <ProjectCreatePage /> },
                ],
            },
            {
                path: "user",
                children: [
                    { element: <UserProfilePage />, index: true },
                    { path: "profile", element: <UserProfilePage /> },
                    { path: "cards", element: <UserCardsPage /> },
                    { path: "list", element: <UserListPage /> },
                    { path: "new", element: <UserCreatePage /> },
                    { path: ":id/edit", element: <UserEditPage /> },
                    { path: "account", element: <UserAccountPage /> },
                ],
            },
            {
                path: "department",
                children: [
                    { path: "list", element: <DepartmentListPage /> },
                    { path: "new", element: <DepartmentCreatePage /> },
                    { path: ":id/edit", element: <DepartmentEditPage /> },
                ],
            },
            {
                path: "travelPolicy",
                children: [
                    { path: "list", element: <TravelPolicyListPage /> },
                    { path: "new", element: <TravelPolicyCreatePage /> },
                    { path: ":id/edit", element: <TravelPolicyEditPage /> },
                ],
            },
            {
                path: "approval",
                children: [{ path: "list", element: <ApprovalListPage /> }],
            },
            {
                path:"reports",
                children:[{element:<ReportsPage/> , index:true}]
            },
            {
                path: "customer",
                children: [
                    { element: <CustomerProfilePage />, index: true },
                    {
                        path: "profile/:customerId",
                        element: <CustomerProfilePage />,
                    },
                    { path: "cards", element: <CustomerCardsPage /> },
                    { path: "list", element: <LeadListPage /> },
                    {
                        path: "individualList",
                        element: <IndividualsListPage />,
                    },
                    { path: "businessList", element: <BusinessListPage /> },
                    { path: "leadList", element: <LeadsListPage /> },

                    { path: "new", element: <CustomerCreatePage /> },
                    { path: ":id/edit", element: <CustomerEditPage /> },
                    { path: "account", element: <CustomerAccountPage /> },
                ],
            },
            {
                path: "sales",
                children: [
                    {
                        path: "salesPipeline",
                        element: <SalesPipelineView />,
                        index: true,
                    },
                    {
                        path: "leadsConverting",
                        element: <LeadsConvertingView />,
                    },
                ],
            },
            {
                path: "provider",
                children: [
                    { element: <ProviderProfilePage />, index: true },
                    {
                        path: "profile/:providerId",
                        element: <ProviderProfilePage />,
                    },
                    { path: "list", element: <ProviderListPage /> },
                    { path: "new", element: <ProviderCreatePage /> },
                    { path: ":id/edit", element: <ProviderEditPage /> },
                ],
            },
            {
                path: "leads",
                children: [
                    { element: <LeadProfilePage />, index: true },
                    { path: "profile/:leadId", element: <LeadProfilePage /> },
                    { path: "cards", element: <LeadCardsPage /> },
                    { path: "list", element: <LeadListPage /> },
                    { path: "new", element: <LeadCreatePage /> },
                    { path: ":id/edit", element: <LeadEditPage /> },
                    { path: "account", element: <LeadAccountPage /> },
                    //{ path: "campaign", element: <LeadCampaignPage /> },
                ],
            },
            {
                path: "groupleads",
                children: [
                    { element: <GroupLeadProfilePage />, index: true },
                    {
                        path: "profile/:leadId",
                        element: <GroupLeadProfilePage />,
                    },
                    { path: "cards", element: <GroupLeadCardsPage /> },
                    { path: "list", element: <GroupLeadListPage /> },
                    { path: "new", element: <GroupLeadCreatePage /> },
                    { path: ":id/edit", element: <GroupLeadEditPage /> },
                    { path: "account", element: <GroupLeadAccountPage /> },
                ],
            },
            {
                path: "campaigns",
                children: [
                    { element: <CampaignProfilePage />, index: true },
                    {
                        path: "profile/:campaignId",
                        element: <CampaignProfilePage />,
                    },
                    { path: "cards", element: <CampaignCardsPage /> },
                    { path: "list", element: <CampaignListPage /> },
                    { path: "new", element: <CampaignCreatePage /> },
                    { path: ":id/edit", element: <CampaignEditPage /> },
                ],
            },
            {
                path: "contactForm",
                children: [
                    { path: "list", element: <CampaignContactPage /> },
                    {
                        path: "new",
                        element: <CampaignCreateContactPage />,
                    },
                    { path: ":id/edit", element: <ContactFormEditPage /> },
                ],
            },
            {
                path: "expense",
                children: [
                    { element: <ExpenseProfilePage />, index: true },
                    {
                        path: "profile/:expenseId",
                        element: <ExpenseProfilePage />,
                    },
                    { path: "list", element: <ExpenseListPage /> },
                    { path: "report", element: <ExpenseReportPage /> },
                    { path: "new", element: <ExpenseCreatePage /> },
                    { path: ":id/edit", element: <ExpenseEditPage /> },
                ],
            },
            {
                path: "income",
                children: [
                    { element: <IncomeProfilePage />, index: true },
                    {
                        path: "profile/:providerId",
                        element: <IncomeProfilePage />,
                    },
                    { path: "list", element: <IncomeListPage /> },
                    { path: "new", element: <IncomeCreatePage /> },
                    { path: ":id/edit", element: <IncomeEditPage /> },
                ],
            },
            {
                path: "ledger",
                children: [
                    { element: <LedgerProfilePage />, index: true },
                    {
                        path: "profile/:providerId",
                        element: <LedgerProfilePage />,
                    },
                    { path: "list", element: <LedgerListPage /> },
                    { path: "new", element: <LedgerCreatePage /> },
                    { path: ":id/edit", element: <LedgerEditPage /> },
                ],
            },
            {
                path: "account",
                children: [
                    { element: <UserProfilePage />, index: true },
                    {
                        path: "profile/:providerId",
                        element: <UserProfilePage />,
                    },
                    { path: "list", element: <AccountListPage /> },
                    { path: "new", element: <AccountCreatePage /> },
                    { path: ":id/edit", element: <AccountEditPage /> },
                ],
            },
            {
                path: "product",
                children: [
                    { element: <ProductListPage />, index: true },
                    { path: "list", element: <ProductListPage /> },
                    { path: ":id", element: <ProductDetailsPage /> },
                    { path: "new", element: <ProductCreatePage /> },
                    { path: ":id/edit", element: <ProductEditPage /> },
                ],
            },
            {
                path: "order",
                children: [
                    { element: <OrderListPage />, index: true },
                    { path: "list", element: <OrderListPage /> },
                    { path: ":id", element: <OrderDetailsPage /> },
                ],
            },
            {
                path: "booking",
                children: [
                    { element: <BookingListPage />, index: true },
                    { path: "list", element: <BookingListPage /> },
                    { path: ":id", element: <BookingDetailsPage /> },
                ],
            },
            {
                path: "invoice",
                children: [
                    { element: <InvoiceListPage />, index: true },
                    { path: "list", element: <InvoiceListPage /> },
                    { path: ":id", element: <InvoiceDetailsPage /> },
                    { path: ":id/edit", element: <InvoiceEditPage /> },
                    { path: "new", element: <InvoiceCreatePage /> },
                ],
            },
            {
                path: "quotation",
                children: [
                    { element: <QuotationListPage />, index: true },
                    { path: "list", element: <QuotationListPage /> },
                    { path: ":id", element: <QuotationDetailsPage /> },
                    { path: ":id/edit", element: <QuotationEditPage /> },
                    { path: "new", element: <QuotationCreatePage /> },
                ],
            },
            {
                path: "post",
                children: [
                    { element: <BlogPostsPage />, index: true },
                    { path: "list", element: <BlogPostsPage /> },
                    { path: ":title", element: <BlogPostPage /> },
                    { path: ":title/edit", element: <BlogEditPostPage /> },
                    { path: "new", element: <BlogNewPostPage /> },
                ],
            },
            {
                path: "job",
                children: [
                    { element: <JobListPage />, index: true },
                    { path: "list", element: <JobListPage /> },
                    { path: ":id", element: <JobDetailsPage /> },
                    { path: "new", element: <JobCreatePage /> },
                    { path: ":id/edit", element: <JobEditPage /> },
                ],
            },
            {
                path: "tour",
                children: [
                    { element: <TourListPage />, index: true },
                    { path: "list", element: <TourListPage /> },
                    { path: ":id", element: <TourDetailsPage /> },
                    { path: "new", element: <TourCreatePage /> },
                    { path: ":id/edit", element: <TourEditPage /> },
                ],
            },
            {
                path: "ourproducts",
                children: [
                    { element: <OurproductsListPage />, index: true },
                    { path: "list", element: <OurproductsListPage /> },
                    { path: ":id", element: <OurproductsDetailsPage /> },
                    { path: "new", element: <OurproductsCreatePage /> },
                    { path: ":id/edit", element: <OurproductsEditPage /> },
                ],
            },
            {
                path: "promotion",
                children: [
                    { element: <PromotionListPage />, index: true },
                    { path: "list", element: <PromotionListPage /> },
                    { path: ":id", element: <PromotionDetailsPage /> },
                    { path: "new", element: <PromotionCreatePage /> },
                    { path: ":id/edit", element: <PromotionEditPage /> },
                ],
            },
            {
                path: "activity",
                children: [
                    { element: <ActivityListPage />, index: true },
                    { path: "list", element: <ActivityListPage /> },
                    {
                        path: ":productCode/:gdsprovider",
                        element: <ActivityDetailsPage />,
                    },
                    {
                        path: "checkout/:code",
                        element: <ActivityCheckoutPage />,
                    },
                ],
            },
            {
                path: "hotel",
                children: [
                    { element: <HotelListPage />, index: true },
                    { path: "list", element: <HotelListPage /> },
                    {
                        path: "hotelDetails/:gdsprovider/:code",
                        element: <HotelDetailsPage />,
                    },
                    { path: ":code/edit", element: <HotelEditPage /> },
                    {
                        path: "checkout/:code/:rateKey",
                        element: <HotelCheckoutPage />,
                    },
                ],
            },

            {
                path: "flight",
                children: [
                    { element: <FlightHomePage />, index: true },
                    {
                        path: "checkout/:offerId",
                        element: <FlightCheckoutPage />,
                    },
                ],
            },

            {
                path: "transfer",
                children: [
                    { element: <TransferHomePage />, index: true },
                    {
                        path: "checkout/:offerId",
                        element: <TransferCheckoutPage />,
                    },
                ],
            },

            {
                path: "aipilot",
                children: [
                    { element: <AiAcquisitionPage />, index: true },
                    { path: "acquisition", element: <AiAcquisitionPage /> },
                    { path: "conversion", element: <AiConversionPage /> },
                    { path: "experience", element: <AiExperiencePage /> },
                    { path: "engagement", element: <AiEngagementPage /> },
                ],
            },

            {
                path: "carRental",
                children: [
                    { element: <CarRentalHomePage />, index: true },
                    {
                        path: "checkout/:offerId",
                        element: <CarRentalCheckoutPage />,
                    },
                ],
            },
            {
                path: "cruises",
                children: [
                    { element: <CruiseListPage />, index: true },
                    { path: "details/:id", element: <CruiseDetailsPage /> },
                ],
            },

            { path: "client-view", element: <ClientViewPage /> },
            { path: "provider-view", element: <ProviderViewPage /> },
            { path: "client-book-view", element: <ClientBookViewPage /> },
            { path: "file-manager", element: <FileManagerPage /> },
            { path: "mail", element: <MailPage /> },
            { path: "chat", element: <ChatPage /> },
            { path: "calendar", element: <CalendarPage /> },
            { path: "kanban", element: <KanbanPage /> },
            { path: "permission", element: <PermissionDeniedPage /> },
            { path: "blank", element: <BlankPage /> },
        ],
    },
];
