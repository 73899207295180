import { getAirpotsList } from "./getAirportList";
import { matchClassValues } from "./matchCabinClassValue";
export const processFlightsOptions = async (flightsOptions, projectData) => {
    const initDate = flightsOptions?.departureDate
        .toISOString()
        .split("T")[0];
    let slices = [];
    let passengers = [];
    const cities = Promise.all(
        projectData.cities.map(async (city) => {
            const response = await getAirpotsList(city);
            return {
                name: city,
                code: response?.[0]?.code,
            };
        }),
    );
    const citiesData = await cities;

    slices.push({
        origin: flightsOptions?.departureCity?.code,
        destination:
            flightsOptions?.arrivalAirport?.code || citiesData?.[0]?.code,
        departure_date: initDate,
        morningFilter:true,
    });
    for (let i = 0; i < citiesData.length - 1; i++) {
        slices.push({
            origin: citiesData?.[i]?.code,
            destination: citiesData?.[i + 1]?.code,
            departure_date: projectData?.endDate?.[i],
        });
    }
    //para la vuelta si se ha seleccionado un aeropuerto de llegada/destino en el formulario de itinerario

    slices.push({
        origin:
            flightsOptions?.arrivalAirport?.code ||
            citiesData?.[citiesData.length - 1]?.code,
        destination: flightsOptions?.departureCity?.code,
        departure_date: projectData?.endDate?.[citiesData.length - 1],
    });

    for (let i = 0; i < flightsOptions.passangers.adults; i++) {
        passengers.push({
            type: "adult",
        });
    }
    for (let i = 0; i < flightsOptions.passangers.child; i++) {
        passengers.push({
            type: "child",
        });
    }
    for (let i = 0; i < flightsOptions.passangers.infants; i++) {
        passengers.push({
            type: "infant_without_seat",
        });
    }

    const max_connections = flightsOptions.directFlight ? 0 : 1;

    localStorage.setItem("flightsOptions", JSON.stringify(flightsOptions));
    return {
        slices,
        passengers,
        max_connections,
        cabin_class: matchClassValues(flightsOptions.class),
        currency: "EUR",
    };
};
