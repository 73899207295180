import { t } from "i18next";
import { paths } from "src/routes/paths";
import { ICONS } from "..";

const baseAdminItems = [
    {
        title: t("Business Admin"),
        path: paths.dashboard.admin.agencies,
        icon: ICONS.folder,
        children: [
            {
                title: t("Empresas"),
                path: paths.dashboard.admin.agencies,
                icon: ICONS.booking,
            },
            {
                title: t("Business Units"),
                path: paths.dashboard.department.list,
                icon: ICONS.booking,
            },
            {
                title: t("Employees"),
                path: paths.dashboard.user.list,
                icon: ICONS.booking,
            },
            {
                title: t("Travel Policies"),
                path: paths.dashboard.travelPolicy.list,
                icon: ICONS.booking,
            },
            {
                title: t("Reports"),
                path: paths.dashboard.travelPolicy.list,
                icon: ICONS.booking,
            },
        ],
    },
];

const approverItems = [
    {
        title: t("Approvals"),
        path: paths.dashboard.approval.list,
        icon: ICONS.booking,
    },
    {
        title: t("Expenses"),
        path: paths.dashboard.expense.list,
        icon: ICONS.booking,
        children: [
            { title: t("List"), path: paths.dashboard.expense.list },
            { title: t("Reports"), path: paths.dashboard.expense.report },
        ],
    },
    {
        title:t("Reports"),
        path:paths.dashboard.reports.root,
        icon:ICONS.booking,
    }
];

const superAdminItems = [
    {
        title: t("Super Admin"),
        path: paths.dashboard.admin.salesreport,
        icon: ICONS.folder,
        children: [
            {
                title: t("Companies"),
                path: paths.dashboard.admin.agencies,
            },
            {
                title: t("Business Units"),
                path: paths.dashboard.admin.departments,
            },
            {
                title: t("Travel Policies"),
                path: paths.dashboard.admin.travelPolicies,
            },
            {
                title: t("Bookings"),
                path: paths.dashboard.admin.bookings,
            },
            {
                title: t("Reports"),
                path: paths.dashboard.admin.salesreport,
                children: [
                    {
                        title: t("Sales"),
                        path: paths.dashboard.admin.salesreport,
                    },
                    {
                        title: t("Businesses"),
                        path: paths.dashboard.admin.clientsreport,
                    },
                ],
            },
        ],
    },
];

export { baseAdminItems, approverItems, superAdminItems };
