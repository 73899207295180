import { paths } from "src/routes/paths";
import { useLocales } from "src/locales";
import { useMemo } from "react";
import { useAuthContext } from "src/auth/hooks";
import { ICONS } from "src/constants";
import { baseAdminItems, approverItems, superAdminItems } from "src/constants";

export function useNavData() {
    const { t } = useLocales();
    const { user } = useAuthContext();

    const role = useMemo(() => user?.role || user?.userRole, [user]);

    const showOptions = useMemo(
        () =>
            role === "superadmin" ||
            // (user?.company?.isParent && role === "business_admin") ||
            role === "business_admin" ||
            role === "approver",
        [role, user],
    );

    const itemsValidated = useMemo(() => {
        if (role === "approver" && showOptions) return approverItems;

        if (role === "business_admin" && showOptions)
            return approverItems.concat(baseAdminItems);

        if (role === "superadmin" && showOptions)
            return [...approverItems, ...baseAdminItems, ...superAdminItems];
    }, [role, showOptions]);

    const data = useMemo(
        () => [
            {
                items: [
                    {
                        title: t("Home"),
                        path: paths.dashboard.home.root,
                        icon: ICONS.label,
                    },
                ],
            },

            // Main Menu
            // ----------------------------------------------------------------------
            {
                items: [
                    // // MAIL

                    // PROJECT
                    {
                        title: (
                            <strong style={{ color: "black", fontSize: 14 }}>
                                {t("My Trips")}
                            </strong>
                        ),
                        path: paths.dashboard.project.list,
                        icon: ICONS.dashboard,
                    },
                    {
                        title: (
                            <strong style={{ color: "black" }}>
                                {t("Flights")}
                            </strong>
                        ),
                        path: paths.dashboard.flight.home,
                        icon: ICONS.flight,
                    },
                    {
                        title: (
                            <strong style={{ color: "black" }}>
                                {t("Hotels")}
                            </strong>
                        ),
                        path: paths.dashboard.hotel.list,
                        icon: ICONS.hotel,
                    },
                    {
                        title: (
                            <strong style={{ color: "black" }}>
                                {t("Transfers")}
                            </strong>
                        ),
                        path: paths.dashboard.transfer.home,
                        icon: ICONS.transfer,
                    },
                    /* {
                        title: (
                            <strong style={{ color: "black" }}>
                                {t("Car Rental")}
                            </strong>
                        ),
                        path: paths.dashboard.project.list,
                        icon: ICONS.carRental,
                    },*/

                    /*
                        title: t("Bookings"),
                        path: paths.dashboard.admin.salesreport,
                        icon: ICONS.booking,
                        children: [
                            {
                                title: t("All Bookings"),
                                path: paths.dashboard.booking.root,
                            },
                            {
                                title: t("Hand-Made Products"),
                                path: paths.dashboard.ourproducts.root,
                            },
                            {
                                title: t("Book a Flight"),
                                path: paths.dashboard.flight.home,
                            },
                            {
                                title: t("Book a Hotel"),
                                path: paths.dashboard.hotel.list,
                            },
                            {
                                title: t("Book an Activity"),
                                path: paths.dashboard.activity.list,
                            },
                            {
                                title: t("Book a Transfer"),
                                path: paths.dashboard.transfer.home,
                            },

                            {
                                title: t("Book a Cruise - Soon"),
                                path: paths.dashboard.cruises.home,
                                icon: ICONS.cruises,
                            },
                            {
                                title: t("Book a Car - Soon"),
                                path: paths.dashboard.carRental.home,
                                icon: ICONS.cruises,
                            },
                        ],
                    },

                    /*}    {
                        title: t("AI Pilot"),
                        path: paths.dashboard.aipilot.acquisition,
                        icon: ICONS.aipilot,
                        children: [
                            {
                                title: t("acquisition"),
                                path: paths.dashboard.aipilot.acquisition,
                            },
                            {
                                title: t("conversion"),
                                path: paths.dashboard.aipilot.conversion,
                            },
                            {
                                title: t("experience"),
                                path: paths.dashboard.aipilot.experience,
                            },
                            {
                                title: t("engagement"),
                                path: paths.dashboard.aipilot.engagement,
                            },
                        ],
                    },
*/

                    /* {
                        title: t("CRM"),
                        path: paths.dashboard.customer.root,
                        icon: ICONS.lead,
                        children: [
                            {
                                title: t("Leads"),
                                path: paths.dashboard.customer.leadList,
                            },
                            {
                                title: t("Customers"),
                                path: paths.dashboard.customer.root, // New overview page
                                children: [
                                    {
                                        title: t("Individuals"),
                                        path: paths.dashboard.customer
                                            .individualList,
                                    },
                                    {
                                        title: t("Businesses"),
                                        path: paths.dashboard.customer
                                            .businessList,
                                    },
                                ],
                            },

                            {
                                title: t("Contact Lists"),
                                path: paths.dashboard.groupleads.list,
                            },
                            {
                                title: t("Customer Acquisition"),
                                path: paths.dashboard.sales.salesPipeline,
                                children: [
                                    {
                                        title: t("Contact Form"),
                                        path: paths.dashboard.contactForm.list,
                                    },
                                    {
                                        title: t("Sales Pipeline"),
                                        path: paths.dashboard.sales
                                            .salesPipeline,
                                    },

                                    {
                                        title: t("Campaigns"),
                                        path: paths.dashboard.campaigns.list,
                                        //icon: ICONS.campaign,
                                    },

                                    {
                                        title: t("Leads Converting"),
                                        path: paths.dashboard.sales
                                            .leadsConverting,
                                    },
                                ],
                            },
                            {
                                title: t("Providers"),
                                path: paths.dashboard.providers.list,
                            },
                            // {
                            //     title: t("Reports & Analytics"),
                            //     // path: paths.dashboard.reports.overview,
                            //     children: [
                            //         {
                            //             title: t("Customer Insights"),
                            //             //  path: paths.dashboard.reports
                            //             //     .customerInsights,
                            //         },
                            //         {
                            //             title: t("Sales Performance"),
                            //             //   path: paths.dashboard.reports
                            //             //    .salesPerformance,
                            //         },
                            //         {
                            //             title: t("Sustainability Impact"),
                            //             //  path: paths.dashboard.reports
                            //             //   .sustainabilityImpact,
                            //         },
                            //     ],
                            // },
                            // {
                            //     title: t("Settings"),
                            //     //   path: paths.dashboard.settings,
                            //     children: [
                            //         {
                            //             title: t("Contact Settings"),
                            //             // path: paths.dashboard.settings
                            //             //     .contactSettings,
                            //         },
                            //         {
                            //             title: t("Pipeline Settings"),
                            //             //  path: paths.dashboard.settings
                            //             //     .pipelineSettings,
                            //         },
                            //         {
                            //             title: t("User Management"),
                            //             //  path: paths.dashboard.settings
                            //             //     .userManagement,
                            //         },
                            //     ],
                            // },
                        ],
                    },

                    {
                        title: t("Accounting"),
                        path: paths.dashboard.admin.salesreport,
                        icon: ICONS.tasks,
                        children: [
                            {
                                title: t("Invoices"),
                                path: paths.dashboard.invoice.root,
                                children: [
                                    {
                                        title: t("list"),
                                        path: paths.dashboard.invoice.root,
                                    },
                                    {
                                        title: t("create"),
                                        path: paths.dashboard.invoice.new,
                                    },
                                ],
                            },
                            {
                                title: t("Quotations"),
                                path: paths.dashboard.quotation.root,
                                children: [
                                    {
                                        title: t("list"),
                                        path: paths.dashboard.quotation.root,
                                    },
                                    {
                                        title: t("create"),
                                        path: paths.dashboard.quotation.new,
                                    },
                                ],
                            },
                            {
                                title: t("Income"),
                                path: paths.dashboard.income.list,
                            },
                            {
                                title: t("Expenses"),
                                path: paths.dashboard.expense.list,
                            },
                            {
                                title: t("Accounts"),
                                path: paths.dashboard.chAccount.list,
                            },
                            {
                                title: t("Ledger"),
                                path: paths.dashboard.ledger.list,
                            },
                        ],
                    },*/
                ],
            },
            /* {
                items: [
                    {
                        title: t("Dashboard"),
                        path: paths.dashboard.salesOverview,
                        icon: ICONS.dashboard,
                        children: [
                            {
                                title: t("Sales Overview"),
                                path: paths.dashboard.salesOverview,
                            },
                            {
                                title: t("Financials"),
                                path: paths.dashboard.financials,
                            },

                            /*}
                            {
                                title: t("Performance Metrics"),
                                path: paths.dashboard.general.booking,
                            },
                            
                        ],
                    },
                    {
                        title: t("Emails"),
                        path: paths.dashboard.mail.root,
                        icon: ICONS.mail,
                    },
                ],
            },
            */
            showOptions && {
                subheader:
                    role === "superadmin" ? t("admin") : t("travelandz admin"),
                items: itemsValidated,
            },
        ],
        [t],
    );

    return data;
}
