import { axiosInstance } from "src/config/axiosInstance";

class UsersService {
    static async fetchUsers() {
        const res = await axiosInstance.get("/users");
        return res.data;
    }
    static async fetchUserLogs(userId, filters) {
        const searchParams = new URLSearchParams();
        if (filters.from) {
            searchParams.append("from", new Date(filters.from).toISOString());
        }
        if (filters.to) {
            searchParams.append("to", new Date(filters.to).toISOString());
        }
        const res = await axiosInstance.get("/users/log-actions/" + userId, {
            params: searchParams,
        });
        return res.data;
    }
    static async fetchUsersSummary(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/users/summary/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async fetchUserDetails(projectId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/users/user/${projectId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async DeleteUserCompany(userId) {
        try {
            const response = await axiosInstance.delete(
                `/users/delete/${userId}`,
            );

            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async CreateUser(data) {
        try {
            const response = await axiosInstance.post(`/users/new`, data);

            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async verifyUserEmail(email) {
        try {
            const response = await axiosInstance.get(
                `/users/email/validate?email=${email}`,
            );

            return response?.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    static async fetchUserLoginDetails(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/users/login/${companyId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching company logo details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createUserAndCompanyByCIF(data) {
        console.log("Creating company with data:", data);
        try {
            const response = await axiosInstance.post(
                "/users/registerUserAndCompany",
                data,
            );
            return response.data;
        } catch (error) {
            console.error("Error creating company:", error);
        }
    }

    static async createUserAndCompanyByID(data) {
        console.log("Creating company with this:", data);
        try {
            const response = await axiosInstance.post(
                "/users/registerUserAndCompany",
                data,
            );
            console.log("Response from theserver:", response);
            return response?.data;
        } catch (error) {
            console.error("Error creating company:", error);
            throw error;
        }
    }

    static async setCustomerStripeId(userId, paymentMethodId) {
        try {
            await axiosInstance.put(`/users/setCustomerStripeId/${userId}`, {
                paymentMethodId,
            });
        } catch (error) {
            console.error("Error setting stripe id:", error);
            throw error;
        }
    }

    static async updateUserCheckoutData(body,userId){

        try{

            await axiosInstance.put(`/users/updateCheckoutData/${userId}`,body);

        }catch(error){
            console.error("Error updating user checkout data:",error);
            throw error;
        }

    }
}

export default UsersService;
