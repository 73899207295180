export const handleBookHotelBody = (bookingData) => {
    return {
        actionType: "checkout",
        adults: 1,
        agencyFees: 0,
        bookingId: bookingData.rateKey,
        bookingType: "hotel",
        checkInDate: bookingData.checkin,
        checkOutDate: bookingData.checkout,
        companyId: bookingData.companyId,
        city: bookingData.city,
        currency: bookingData.currency,
        gdsprovider: bookingData.gdsprovider,
        genderC: "other",
        hotelCode: bookingData.code,
        hotelName: bookingData.name,
        kids: 0,
        netPrice: bookingData.netPrice,
        sellingPrice: bookingData.sellingPrice || +bookingData.totalPrice,
        totalAmount: bookingData.totalAmount || +bookingData.totalPrice,
        rateKey: bookingData.rateKey,
        difference: bookingData.difference,
        comercialPrice: bookingData.comercialPrice || +bookingData.totalPrice,
        selectedRooms: [
            {
                adults: 1,
                agencyFees: 0,
                kids: 0,
                netPrice: bookingData.netPrice,
                numberOfRooms: 1,
                rateKey: bookingData.rateKey,
                selectedRate: bookingData.rateKey,
                sellingPrice: bookingData.sellingPrice || +bookingData.totalPrice,
                boardCode: bookingData.rateKey?.split("|")[7], 
                code: bookingData.rateKey?.split("|")[5], 
            },
        ],
        roomsQuery: [
            {
                adults: 1,
                childrenAges: [],
                kids: 0,
                kidsAges: [],
            },
        ],
    };
};
