const APPROVAL_OPTIONS={
    travel:"travel",
    hotel:"accommodation",
    meals:"meals",
    flight:"flight",
    transfer:"transfer",
    other:"other",
    transfer_tz:"transfer-tz",
}

export default APPROVAL_OPTIONS;