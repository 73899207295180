import { useCallback, useEffect, useMemo, useReducer, useState } from "react";

import { AuthContext } from "./auth-context";
import PropTypes from "prop-types";
import { axiosInstance } from "src/config/axiosInstance";
import { endpoints } from "src/utils/axios";
import { setSession } from "./utils";
import { storageKeys } from "src/utils/sessionStorage";
import { useLocales } from "src/locales";

const initialState = {
    user: null,
    loading: true,
    paymentMethods: [],
    isValidSubscription: false,
};

const reducer = (state, action) => {
    if (action.type === "INITIAL") {
        return {
            loading: false,
            user: action.payload.user,
            /*  role: action.payload.role, */ // Set the user's role during initialization
            paymentMethods: action.payload.paymentMethods, // Set the user's payment methods during initialization
            isValidSubscription: action.payload.isValidSubscription,
            travelPolicies: action.payload.travelPolicies,
        };
    }
    if (action.type === "LOGIN") {
        return {
            ...state,
            user: action.payload.user,
            /*  role: action.payload.user.role, */ // Set the user's role during login
            paymentMethods: action.payload.paymentMethods, // Set the user's payment methods during login
            isValidSubscription: action.payload.isValidSubscription,
            travelPolicies: action.payload.travelPolicies,
        };
    }
    if (action.type === "REGISTER") {
        return {
            ...state,
            user: action.payload.user,
        };
    }
    if (action.type === "LOGOUT") {
        return {
            ...state,
            user: null,
            paymentMethods: [],
            isValidSubscription: false,
        };
    }
    return state;
};

export function AuthProvider({ children }) {
    const { onChangeLang } = useLocales();
    const [state, dispatch] = useReducer(reducer, initialState);

    const initialize = useCallback(async () => {
        try {
            const accessToken = sessionStorage.getItem(storageKeys.TOKEN);
            const userId = sessionStorage.getItem(storageKeys.USER_ID);

            const data = {
                accessToken,
                userId,
            };

            const response = await axiosInstance.post(endpoints.auth.me, data);

            const {
                user,
                userSession,
                paymentMethods,
                isValidSubscription,
                travelPolicies,
            } = response.data;

            setSession(accessToken, user);

            dispatch({
                type: "INITIAL",
                payload: {
                    user,
                    paymentMethods,
                    isValidSubscription,
                    travelPolicies,
                },
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: "INITIAL",
                payload: {
                    user: null,
                    paymentMethods: [],
                    isValidSubscription: false,
                },
            });
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    const login = useCallback(async (email, password, forceLogin = false) => {
        try {
            const response = await axiosInstance.post(
                endpoints.auth.login,
                {
                    email,
                    password,
                },
                { params: forceLogin ? { forceLogin: true } : {} },
            );
            const {
                company,
                userSession,
                isValidSubscription,
                travelPolicies,
            } = response.data;

            setSession(userSession?.token, userSession);
            sessionStorage.setItem(storageKeys.TOKEN, userSession?.token);
            sessionStorage.setItem(storageKeys.USER_ID, userSession?.userId);
            sessionStorage.setItem(
                storageKeys.USER_CITY,
                userSession?.userCity,
            );
            sessionStorage.setItem(storageKeys.USER_LAT, userSession?.userLat);
            sessionStorage.setItem(
                storageKeys.USER_LONG,
                userSession?.userLong,
            );
            sessionStorage.setItem(
                storageKeys.USER_ROLE,
                userSession?.userRole,
            );
            sessionStorage.setItem(storageKeys.COMPANY_ID, company?.companyId);
            sessionStorage.setItem(storageKeys.CURRENCY, userSession?.currency);
            sessionStorage.setItem(storageKeys.LOGO, company?.avatarUrl);
            sessionStorage.setItem(
                storageKeys.COMPANY_NAME,
                company?.companyName,
            );
            sessionStorage.setItem(storageKeys.COMPANY_VAT, company?.vat);
            sessionStorage.setItem(storageKeys.BANK_NAME, company?.bankName);
            sessionStorage.setItem(
                storageKeys.BANK_ACCOUNT,
                company?.bankAccount,
            );
            sessionStorage.setItem(storageKeys.SWIFT, company?.swift);
            sessionStorage.setItem(storageKeys.EMAIL, company?.email);

            let customerLanguage = userSession?.language;
            if (userSession?.language == "Spanish") {
                customerLanguage = "es";
            }

            if (userSession?.language == "French") {
                customerLanguage = "fr";
            }
            if (userSession?.language == "English") {
                customerLanguage = "en";
            }
            sessionStorage.setItem(storageKeys.LANGUAGE, customerLanguage);
            onChangeLang(customerLanguage);
            sessionStorage.setItem(
                storageKeys.BANK_ACCOUNT_OWNER,
                company?.bankAccountOwner,
            );
            sessionStorage.setItem(
                storageKeys.COMPANY_PARENT_NAME,
                company?.companyParentName,
            );
            sessionStorage.setItem(
                storageKeys.ONBOARDING_STATUS,
                company?.onboardingStatus,
            );
            sessionStorage.setItem(
                storageKeys.APPLY_TAX,
                userSession?.applicableTax,
            );
            sessionStorage.setItem(
                storageKeys.SUBSCRIPTION_PLAN,
                userSession?.subscriptionPlan,
            );
            sessionStorage.setItem(
                storageKeys.PAYMENT_METHODS,
                userSession?.paymentMethods,
            );
            sessionStorage.setItem(
                storageKeys.HOTELS_FEE,
                userSession?.feesHotels,
            );
            sessionStorage.setItem(
                storageKeys.ACTIVITIES_FEE,
                userSession?.fees.activitiesFee,
            );
            sessionStorage.setItem(
                storageKeys.TRANSFERS_FEE,
                userSession?.fees.transfersFee,
            );
            sessionStorage.setItem(storageKeys.CARS_FEE, userSession?.feesCars);
            sessionStorage.setItem(
                storageKeys.FLIGTHS_DOMESTIC_FEE,
                userSession?.fees.flightsDomesticFee,
            );
            sessionStorage.setItem(
                storageKeys.FLIGTHS_INTERNATIONAL_FEE,
                userSession?.fees.flightsInternationalFee,
            );
            sessionStorage.setItem(
                storageKeys.CRUISES_FEE,
                userSession?.fees.CruisesFee,
            );
            sessionStorage.setItem(
                storageKeys.PACKAGE_FEE,
                userSession?.fees.packagesFee,
            );
            //console.log("userSession*********", userSession);
            console.log(sessionStorage.getItem(storageKeys.PACKAGE_FEE));
            console.log(sessionStorage.getItem(storageKeys.FLIGTHS_FEE));
            console.log(sessionStorage.getItem(storageKeys.TRANSFERS_FEE));
            console.log(storageKeys.PACKAGE_FEE);

            dispatch({
                type: "LOGIN",
                payload: {
                    user: userSession,
                    isValidSubscription,
                    travelPolicies,
                },
            });
            return { status: "SUCCESS" };
        } catch (error) {
            if (error?.loginDate) {
                return {
                    status: "ACTIVE_SESSION",
                    responseData: {
                        loginDate: error.loginDate,
                        device: error.device,
                    },
                };
            }
            return { status: "ERROR" };
        }
    }, []);
    const adminLogin = useCallback(async (userId) => {
        try {
            const response = await axiosInstance.post(
                endpoints.users.adminLogin(userId),
            );
            const {
                company,
                userSession,
                isValidSubscription,
                travelPolicies,
            } = response.data;
            setSession(null); // Clear previous session

            sessionStorage.setItem(
                storageKeys.SUPERADMIN_TOKEN,
                userSession.superAdminToken,
            );
            setSession(userSession?.token, userSession);
            sessionStorage.setItem(storageKeys.TOKEN, userSession?.token);
            sessionStorage.setItem(storageKeys.USER_ID, userSession?.userId);
            sessionStorage.setItem(
                storageKeys.USER_CITY,
                userSession?.userCity,
            );
            sessionStorage.setItem(storageKeys.USER_LAT, userSession?.userLat);
            sessionStorage.setItem(
                storageKeys.USER_LONG,
                userSession?.userLong,
            );
            sessionStorage.setItem(
                storageKeys.USER_ROLE,
                userSession?.userRole,
            );
            sessionStorage.setItem(storageKeys.COMPANY_ID, company?.companyId);
            sessionStorage.setItem(storageKeys.CURRENCY, userSession?.currency);
            sessionStorage.setItem(storageKeys.LOGO, company?.avatarUrl);
            sessionStorage.setItem(
                storageKeys.COMPANY_NAME,
                company?.companyName,
            );
            sessionStorage.setItem(storageKeys.COMPANY_VAT, company?.vat);
            sessionStorage.setItem(storageKeys.BANK_NAME, company?.bankName);
            sessionStorage.setItem(
                storageKeys.BANK_ACCOUNT,
                company?.bankAccount,
            );
            sessionStorage.setItem(storageKeys.SWIFT, company?.swift);
            sessionStorage.setItem(storageKeys.EMAIL, company?.email);

            let customerLanguage = userSession?.language;
            if (userSession?.language == "Spanish") {
                customerLanguage = "es";
            }

            if (userSession?.language == "French") {
                customerLanguage = "fr";
            }
            if (userSession?.language == "English") {
                customerLanguage = "en";
            }
            sessionStorage.setItem(storageKeys.LANGUAGE, customerLanguage);
            onChangeLang(customerLanguage);
            sessionStorage.setItem(
                storageKeys.BANK_ACCOUNT_OWNER,
                company?.bankAccountOwner,
            );
            sessionStorage.setItem(
                storageKeys.COMPANY_PARENT_NAME,
                company?.companyParentName,
            );
            sessionStorage.setItem(
                storageKeys.ONBOARDING_STATUS,
                company?.onboardingStatus,
            );
            sessionStorage.setItem(
                storageKeys.APPLY_TAX,
                userSession?.applicableTax,
            );
            sessionStorage.setItem(
                storageKeys.SUBSCRIPTION_PLAN,
                userSession?.subscriptionPlan,
            );
            sessionStorage.setItem(
                storageKeys.PAYMENT_METHODS,
                userSession?.paymentMethods,
            );
            sessionStorage.setItem(
                storageKeys.HOTELS_FEE,
                userSession?.feesHotels,
            );
            sessionStorage.setItem(
                storageKeys.ACTIVITIES_FEE,
                userSession?.fees.activitiesFee,
            );
            sessionStorage.setItem(
                storageKeys.TRANSFERS_FEE,
                userSession?.fees.transfersFee,
            );
            sessionStorage.setItem(storageKeys.CARS_FEE, userSession?.feesCars);
            sessionStorage.setItem(
                storageKeys.CRUISES_FEE,
                userSession?.fees.cruisesFee,
            );
            sessionStorage.setItem(
                storageKeys.FLIGTHS_DOMESTIC_FEE,
                userSession?.fees.flightsDomesticFee,
            );
            sessionStorage.setItem(
                storageKeys.FLIGTHS_INTERNATIONAL_FEE,
                userSession?.fees.flightsInternationalFee,
            );
            sessionStorage.setItem(
                storageKeys.PACKAGE_FEE,
                userSession?.fees.packagesFee,
            );
            //console.log("userSession*********", userSession);
            console.log(sessionStorage.getItem(storageKeys.PACKAGE_FEE));
            console.log(sessionStorage.getItem(storageKeys.FLIGTHS_FEE));
            console.log(sessionStorage.getItem(storageKeys.TRANSFERS_FEE));
            console.log(storageKeys.PACKAGE_FEE);

            dispatch({
                type: "LOGIN",
                payload: {
                    user: userSession,
                    isValidSubscription,
                    travelPolicies,
                },
            });
            return true;
        } catch (error) {
            return false;
        }
    }, []);

    const register = useCallback(
        async (email, password, firstName, lastName) => {
            const data = {
                email,
                password,
                firstName,
                lastName,
            };

            const response = await axiosInstance.post(
                endpoints.auth.register,
                data,
            );

            const { accessToken, user } = response.data;

            sessionStorage.setItem(storageKeys.TOKEN, accessToken);

            dispatch({
                type: "REGISTER",
                payload: {
                    user,
                },
            });
        },
        [],
    );

    const logout = useCallback(async () => {
        axiosInstance
            .post(endpoints.auth.logout)
            .catch((err) => console.error("Error in logout: ", err));
        setSession(null);
        dispatch({
            type: "LOGOUT",
        });
    }, []);

    const checkAuthenticated = sessionStorage.getItem(storageKeys.TOKEN)
        ? "authenticated"
        : "unauthenticated";

    const status = state.loading ? "loading" : checkAuthenticated;

    const [paymentMethod, setPaymentMethod] = useState(null);

    const [userInfo, setUserInfo] = useState(null);

    const memoizedValue = useMemo(
        () => ({
            user: state.user,
            paymentMethods: state.paymentMethods,
            isValidSubscription: state.isValidSubscription,
            method: "jwt",
            loading: status === "loading",
            authenticated: status === "authenticated",
            unauthenticated: status === "unauthenticated",
            //
            login,
            adminLogin,
            register,
            logout,
            paymentMethod,
            setPaymentMethod,
            userInfo,
            setUserInfo,
            travelPolicies: state.travelPolicies,
        }),
        [
            login,
            logout,
            register,
            state.user,
            state.paymentMethods,
            status,
            paymentMethod,
            setPaymentMethod,
            userInfo,
            setUserInfo,
        ],
    );

    return (
        <AuthContext.Provider value={memoizedValue}>
            {children}
        </AuthContext.Provider>
    );
}

AuthProvider.propTypes = {
    children: PropTypes.node,
};
