import { axiosInstance } from "src/config/axiosInstance";

class BookingsService {
    static async fetchBookingsSummary(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/bookings/summary/${companyId}`,
                );
                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async deleteBooking(bookingId) {
        try {
            const response = await axiosInstance.post(
                `/bookings/delete/${bookingId}`,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async fetchBookingsUser(userId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/bookings/user/${userId}`,
                );
                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async fetchBookingsList(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/bookings/company/${companyId}`,
                );
                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async fetchWalletBalanceFromAPI(companyId, maxRetries, retryDelay) {
        if (!companyId) {
            throw new Error("companyId is not provided");
        }

        return axiosInstance.get(`/wallet/balance/${companyId}`);
    }

    static async fetchBookingDetails(bookingId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/bookings/${bookingId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async createBooking(bookingData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    "/bookings/new",
                    bookingData,
                );
                return response;
            } catch (error) {
                console.error("Error creating new booking:", error);
                retries++;
                if (retries === maxRetries) return error;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async confirmBooking(bookingData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    "/bookings/confirm",
                    bookingData,
                );
                return response;
            } catch (error) {
                console.error("Error creating new booking:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createCustomerBookingIntent(
        projectId,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/bookings/customerIntent/new/${projectId}`,
                    { headers: { "tz-api-key": process.env.REACT_APP_TZ_API_KEY } }
                );
                return response;
            } catch (error) {
                console.error("Error creating customer booking intent:", error);
                retries++;
                if (retries === maxRetries) throw error;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getBookingDetailsForPayment(
        bookingId,
        maxRetries=3,
        retryDelay=1000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/bookings/getBookingDetailsForPayment/${bookingId}`,
                    {
                        timeout:7000
                    }
                );
                return response;
            } catch (error) {
                console.error("Error fetching booking details for payment:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async getBookingProject(projectId){
        try{

            const response=await axiosInstance.get(`/bookings/project/${projectId}`);

            return response.data;

        }catch(error){
            console.error("Error fetching project details:", error);
        }
    }
}

export default BookingsService;
