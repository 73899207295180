const BASE_STEP_VALUES=[
    {
        step:0,
        label:"Select a project"
    },
]

const FLIGHT_STEP_VALUES=[
    BASE_STEP_VALUES[0],
    {
        step:1,
        label:"Confirm passenger details"
    },
]

export {
    BASE_STEP_VALUES,
    FLIGHT_STEP_VALUES
}