import { HeaderShadow, LoginButton } from "../_common";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import { paths } from "src/routes/paths";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { HEADER } from "../config-layout";
import Logo from "src/components/logo-saas";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { bgBlur } from "src/theme/css";
import { useOffSetTop } from "src/hooks/use-off-set-top";
import { useResponsive } from "src/hooks/use-responsive";
import { useTheme } from "@mui/material/styles";

export default function Header() {
    const theme = useTheme();

    const mdUp = useResponsive("up", "md");

    const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

    return (
        <AppBar>
            <Toolbar
                disableGutters
                sx={{
                    height: {
                        xs: HEADER.H_MOBILE,
                        md: HEADER.H_DESKTOP,
                    },
                    transition: theme.transitions.create(["height"], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                    }),
                    ...(offsetTop && {
                        ...bgBlur({
                            color: theme.palette.background.default,
                        }),
                        height: {
                            md: HEADER.H_DESKTOP_OFFSET,
                        },
                    }),
                }}
            >
                <Container
                    sx={{ height: 1, display: "flex", alignItems: "center" }}
                >
                    <Logo />

                    <Box sx={{ flexGrow: 1 }} />

                    <Button
                        variant="contained"
                        target="_blank"
                        rel="noopener"
                        href={paths.pricing}
                    >
                        Pricing
                    </Button>

                    <Stack
                        alignItems="center"
                        direction={{ xs: "row", md: "row-reverse" }}
                        marginLeft={1}
                    >
                        <LoginButton />
                    </Stack>
                </Container>
            </Toolbar>

            {offsetTop && <HeaderShadow />}
        </AppBar>
    );
}
