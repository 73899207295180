import { _mock } from "./_mock";

// ----------------------------------------------------------------------

export const _carouselsMembers = [...Array(6)].map((_, index) => ({
    id: _mock.id(index),
    name: _mock.fullName(index),
    role: _mock.role(index),
    avatarUrl: _mock.image.portrait(index),
}));

// ----------------------------------------------------------------------

export const _faqs = [...Array(8)].map((_, index) => ({
    id: _mock.id(index),
    value: `panel${index + 1}`,
    heading: `Questions ${index + 1}`,
    detail: _mock.description(index),
}));

// ----------------------------------------------------------------------

export const _addressBooks = [...Array(24)].map((_, index) => ({
    id: _mock.id(index),
    primary: index === 0,
    name: _mock.fullName(index),
    email: _mock.email(index + 1),
    fullAddress: _mock.fullAddress(index),
    phoneNumber: _mock.phoneNumber(index),
    company: _mock.companyName(index + 1),
    addressType: index === 0 ? "Home" : "Office",
}));

// ----------------------------------------------------------------------

export const _contacts = [...Array(20)].map((_, index) => {
    const status =
        (index % 2 && "online") ||
        (index % 3 && "offline") ||
        (index % 4 && "alway") ||
        "busy";

    return {
        id: _mock.id(index),
        status,
        role: _mock.role(index),
        email: _mock.email(index),
        name: _mock.fullName(index),
        phoneNumber: _mock.phoneNumber(index),
        lastActivity: _mock.time(index),
        avatarUrl: _mock.image.avatar(index),
        address: _mock.fullAddress(index),
    };
});

// ----------------------------------------------------------------------

export const _notifications = [...Array(9)].map((_, index) => ({
    id: _mock.id(index),
    avatarUrl: [
        _mock.image.avatar(1),
        _mock.image.avatar(2),
        _mock.image.avatar(3),
        _mock.image.avatar(4),
        _mock.image.avatar(5),
        null,
        null,
        null,
        null,
        null,
    ][index],
    type: [
        "friend",
        "project",
        "file",
        "tags",
        "payment",
        "order",
        "chat",
        "mail",
        "delivery",
    ][index],
    category: [
        "Communication",
        "Project UI",
        "File Manager",
        "File Manager",
        "File Manager",
        "Order",
        "Order",
        "Communication",
        "Communication",
    ][index],
    isUnRead: _mock.boolean(index),
    createdAt: _mock.time(index),
    title:
        (index === 0 &&
            `<p><strong>Deja Brady</strong> sent you a friend request</p>`) ||
        (index === 1 &&
            `<p><strong>Jayvon Hull</strong> mentioned you in <strong><a href='#'>Minimal UI</a></strong></p>`) ||
        (index === 2 &&
            `<p><strong>Lainey Davidson</strong> added file to <strong><a href='#'>File Manager</a></strong></p>`) ||
        (index === 3 &&
            `<p><strong>Angelique Morse</strong> added new tags to <strong><a href='#'>File Manager<a/></strong></p>`) ||
        (index === 4 &&
            `<p><strong>Giana Brandt</strong> request a payment of <strong>$200</strong></p>`) ||
        (index === 5 && `<p>Your order is placed waiting for shipping</p>`) ||
        (index === 6 &&
            `<p>Delivery processing your order is being shipped</p>`) ||
        (index === 7 && `<p>You have new message 5 unread messages</p>`) ||
        (index === 8 && `<p>You have new mail`) ||
        "",
}));

// ----------------------------------------------------------------------

export const _mapContact = [
    {
        latlng: [33, 65],
        address: _mock.fullAddress(1),
        phoneNumber: _mock.phoneNumber(1),
    },
    {
        latlng: [-12.5, 18.5],
        address: _mock.fullAddress(2),
        phoneNumber: _mock.phoneNumber(2),
    },
];

// ----------------------------------------------------------------------

export const _socials = [
    {
        value: "facebook",
        name: "FaceBook",
        icon: "eva:facebook-fill",
        color: "#1877F2",
        path: "https://www.facebook.com/profile.php?id=100091462146711",
    },
    {
        value: "instagram",
        name: "Instagram",
        icon: "ant-design:instagram-filled",
        color: "#E02D69",
        path: "https://www.instagram.com/travelandzai/",
    },
    {
        value: "linkedin",
        name: "Linkedin",
        icon: "eva:linkedin-fill",
        color: "#007EBB",
        path: "https://www.linkedin.com/company/travelandz",
    },
    {
        value: "twitter",
        name: "Twitter",
        icon: "eva:twitter-fill",
        color: "#00AAEC",
        path: "https://twitter.com/travelandz",
    },
];

// ----------------------------------------------------------------------

export const _homePlans = [...Array(3)].map((_, index) => ({
    license: ["Standard", "Standard Plus", "Extended"][index],
    commons: [
        "Easy Task Manager",
        "Automatic Personalized Emails",
        "AI Travel Planner",
    ],
    options: [
        "Book Hotels, Activities, Transfers",
        "FootPrint Calculation & Offset",
        "Automatic Requests for Quotaions",
        "Automatic Lead Management",
        "Premium Support",
    ],
    icons: [
        "/assets/icons/platforms/ic_figma.svg",
        "/assets/icons/platforms/ic_js.svg",
        "/assets/icons/platforms/ic_ts.svg",
    ],
}));

// ----------------------------------------------------------------------

export const _pricingPlans = [
    {
        subscription: "Trial Period",
        level: 0,
        price: 0,
        caption: "3 Months Free, Cancel Anytime",
        caption2: "Start Right Now Saving Costs",
        lists: [
            "Access to flight, hotel, and car rental booking platform",
            "Basic travel planning tools (itineraries, reminders)",
            "Support via email or chat during business hours",
            "Basic travel expense reports for the company",
            "Monthly report with booking details and general expenses",
            "Manual approval process for trips",
            "Limited to up to 10 employees",
            "Manual payments with stored credit/debit cards",
            "Monthly or per-booking billing",
            "Access to standard rates for flights and hotels",
            "Basic team travel management (limited features)",
            "Cancel Anytime",
        ],
        labelAction: "Start For Free",
    },
    {
        subscription: "Basic Membership",
        price: 9.99,
        caption: "Best Option for Small Teams",
        caption2: "**No monthly fee if you make 2 bookings in a month",
        lists: [
            "Access to flight, hotel, and car rental booking platform",
            "Basic travel planning tools (itineraries, reminders)",
            "Support via email or chat during business hours",
            "Basic travel expense reports for the company",
            "Monthly report with booking details and general expenses",
            "Manual approval process for trips",
            "Limited to up to 10 employees",
            "Manual payments with stored credit/debit cards",
            "Monthly or per-booking billing",
            "Access to standard rates for flights and hotels",
            "Basic team travel management (limited features)",
            "Cancel Anytime",
        ],
        labelAction: "Choose Basic",
    },

    {
        subscription: "Premium Membership",
        price: 39.99,
        caption: "Unlock exclusive benefits",
        caption2: "**No monthly fee if you make 2 bookings in a month",
        lists: [
            "Priority bookings, automatic upgrades (when available), and exclusive rates",
            "Advanced travel planning tools with calendar integrations and personalized itineraries",
            "24/7 premium customer support with dedicated agents and personalized phone service",
            "Access to an account manager for real-time assistance",
            "Custom travel expense reports with advanced filters",
            "Real-time reports and interactive dashboards for better control",
            "Automated travel approval processes based on predefined company policies",
            "Real-time budget usage alerts",
            "Unlimited employees and administrators on the platform",
            "Integration with financial management systems (ERP) for automated billing",
            "Installment payments or corporate credit line options",
            "Access to discounted corporate rates with better conditions (free cancellation, flexible rates)",
            "Bookings with premium airlines and hotels, including added perks (lounge access, upgrades)",
            "Advanced team travel management tools, including delegation and automated approvals",
            "Integration with project management tools and automated team travel reports",
            "Centralized management of loyalty points for airlines, hotels, and car rentals",
            "Accumulate special benefits such as extra miles or free nights",
            "Advanced security features, such as real-time traveler tracking for emergencies",
            "Compliance reports (e.g., GDPR)",
            "Cancel Anytime",
        ],
        labelAction: "Choose Premium",
    },
];

// ----------------------------------------------------------------------

export const _testimonials = [
    {
        name: _mock.fullName(1),
        postedDate: _mock.time(1),
        ratingNumber: _mock.number.rating(1),
        avatarUrl: _mock.image.avatar(1),
        content: `Travelandz software faciltates the daily tasks of my travel agency. It helps me save time and be more efficient.`,
    },
    {
        name: _mock.fullName(2),
        postedDate: _mock.time(2),
        ratingNumber: _mock.number.rating(2),
        avatarUrl: _mock.image.avatar(2),
        content: `I can't imagine running our travel agency without Travelandz. The software's AI-driven insights have boosted our sales and helped us stay competitive in the market. It's user-friendly and has improved our overall efficiency.`,
    },
    {
        name: _mock.fullName(3),
        postedDate: _mock.time(3),
        ratingNumber: _mock.number.rating(3),
        avatarUrl: _mock.image.avatar(3),
        content: `The Travelandz team has been incredibly helpful and professional, ensuring that we have all the support we need. Highly recommended!`,
    },
    {
        name: _mock.fullName(4),
        postedDate: _mock.time(4),
        ratingNumber: _mock.number.rating(4),
        avatarUrl: _mock.image.avatar(4),
        content: `We've been using Travelandz for a while now, and it's been a fantastic addition to our travel agency. The dashboard is user-friendly, and makes our daily tasks a breeze. We recommend Travelandz to every travel agency!`,
    },
    {
        name: _mock.fullName(5),
        postedDate: _mock.time(5),
        ratingNumber: _mock.number.rating(5),
        avatarUrl: _mock.image.avatar(5),
        content: `Travelandz is the all-in-one platform our travel agency was looking for. It handles everything from trip planning to client management and beyond.`,
    },
    {
        name: _mock.fullName(6),
        postedDate: _mock.time(6),
        ratingNumber: _mock.number.rating(6),
        avatarUrl: _mock.image.avatar(6),
        content: `The software has truly transformed our travel agency's accountability. With its intuitive features, it has made generating invoices a seconds. Thanks!`,
    },
];
